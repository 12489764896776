































import { Component, Prop, Vue } from 'vue-property-decorator';

import ProductCoreModel from '@/modules/products/models/ProductCoreModel';
import ProductAvailability from '@/app/modules/products/components/partials/details/ProductAvailability.vue';
import TagsCloud from '@/app/modules/tags/components/partials/TagsCloud.vue';
import ProductsListViewEnum from '@/app/modules/products/enums/ProductsListViewsEnum';
import { amountInPackText } from '@/app/modules/products/productsHelpers';

@Component({
  components: {
    TagsCloud,
    ProductAvailability,
  },
})
export default class ProductItemDetails extends Vue {
  /**
   * Props
   */
  @Prop() private product!: ProductCoreModel;
  @Prop() private view!: ProductsListViewEnum;

  /**
   * Display getters
   */
  private get displayTagsCloud(): boolean {
    return this.$vuetify.breakpoint.smAndUp && this.view === ProductsListViewEnum.LIST;
  }

  private get displayAmountInUnit(): boolean {
    return !!this.product?.amountInPack;
  }

  /**
   * Getters
   */
  private get amountInUnitText(): string {
    return amountInPackText(this.product);
  }

  /**
   * Handlers
   */
  private onLinkClick() {
    this.$emit('detailsClick');
  }
}
