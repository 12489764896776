




































import { Component, Prop, Vue } from 'vue-property-decorator';

import ProductCoreModel from '@/modules/products/models/ProductCoreModel';
import Card from '@/shared/resources/components/cards/Card.vue';
import Button from '@/shared/resources/components/buttons/Button.vue';
import Icon from '@/shared/resources/components/Icon.vue';
import InputField from '@/shared/resources/components/forms/fields/InputField.vue';
import NumberField from '@/shared/resources/components/forms/fields/NumberField.vue';
import ProductItemImage from './ProductItemImage.vue';
import ProductItemDetails from './ProductItemDetails.vue';
import ProductPrice from '../details/ProductPrice.vue';
import ProductAddToCartButton from '../details/ProductAddToCartButton.vue';
import GridSpacer from '@/shared/resources/components/grid/GridSpacer.vue';
import UISettings from '@/app/lib/settings/UISettings';
import ProductsListViewEnum from '@/app/modules/products/enums/ProductsListViewsEnum';

@Component({
  components: {
    GridSpacer,
    ProductAddToCartButton,
    ProductPrice,
    ProductItemDetails,
    ProductItemImage,
    NumberField,
    InputField,
    Icon,
    Button,
    Card,
  },
})
export default class ProductItem extends Vue {
  /**
   * Props
   */
  @Prop() private product!: ProductCoreModel;
  @Prop() private view!: ProductsListViewEnum;

  /**
   * Getters
   */
  private get detailsRoute(): any {
    return {
      name: 'products.details',
      params: {
        id: this.product.id,
      },
    };
  }

  /**
   * Display getters
   */
  private get displayAddToCartInputButton(): boolean {
    return this.product.isAvailable;
  }

  private get displayPriceUnit(): boolean {
    return this.$vuetify.breakpoint.smAndUp && this.view === ProductsListViewEnum.LIST;
  }

  /**
   * Class names
   */
  private get itemClassNames(): object {
    return {
      'product-item': true,
      'product-item--unavailable': !this.product.isAvailable,
    };
  }

  /**
   * Methods
   */
  private openDetailsModal() {
    UISettings.openSideModal('ProductDetails', { id: this.product.id }, 'Szczegóły produktu');
  }

  /**
   * Handlers
   */
  private onDetailsClick() {
    this.openDetailsModal();
  }
}
